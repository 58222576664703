<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-text md-card-header-green">
          <div class="card-text">
            <h4 class="title">Search</h4>
          </div>
        </md-card-header>

        <md-card-content>
          <div class="md-layout">
            <label class="md-layout-item md-size-15 md-form-label">
              Suchbegriff (z.B. Hörbuchtitel)
            </label>
            <div class="md-layout-item">
              <md-field>
                <md-input v-model="helper" type="text"></md-input>
                <span class="md-helper-text">A block of help text that breaks onto a new line.</span>
              </md-field>
            </div>
          </div>

                    <div class="md-layout">
         <label class="md-layout-item md-size-15 md-form-label">
                  Sprecherauswahl
                </label>
            <div class="md-layout-item">
             
           <md-field>
                    <label for="movies">Sprecher</label>
                    <md-select v-model="speakers" name="movies" id="movies" multiple>
                      <md-option value="fight-club">speaker-one</md-option>
                      <md-option value="godfather">speaker-two</md-option>
                      <md-option value="godfather-ii">speaker-three</md-option>
                      <md-option value="godfather-iii">speaker-four</md-option>
                      <md-option value="godfellas">speaker-five</md-option>
                      <md-option value="pulp-fiction">speaker-six</md-option>
                      <md-option value="scarface">speaker-seven</md-option>
                    </md-select>
                  </md-field>
          
            </div>
          </div>


                   <div class="md-layout">
        <div class="md-layout-item md-size-100 md-small-size-100 text-right">
                  <md-checkbox v-model="checkbox1">Audio-Dateien vorhanden</md-checkbox>
                </div>
          </div>
          <div class="md-layout">
            <div class="md-layout-item md-small-size-100">
              <div class="text-right">
                düster
              </div>
            </div>
            <div class="md-layout-item md-small-size-100">
              <div class="text-center">
                <slider v-model="sliders.simple1"> </slider>
              </div>
            </div>
            <div class="md-layout-item md-small-size-100">
              <div class="text-left">
                heiter
              </div>
            </div>
          </div>

           <div class="md-layout">
            <div class="md-layout-item md-small-size-100">
              <div class="text-right">
                spannend
              </div>
            </div>
            <div class="md-layout-item md-small-size-100">
              <div class="text-center">
                <slider v-model="sliders.simple2"> </slider>
              </div>
            </div>
            <div class="md-layout-item md-small-size-100">
              <div class="text-left">
                entspannend
              </div>
              </div>
          </div>

           <div class="md-layout">
            <div class="md-layout-item md-small-size-100">
              <div class="text-right">
                bestimmt
              </div>
            </div>
            <div class="md-layout-item md-small-size-100">
              <div class="text-center">
                <slider v-model="sliders.simple3"> </slider>
              </div>
            </div>
            <div class="md-layout-item md-small-size-100">
              <div class="text-left">
                suchend
              </div>
              </div>
          </div>

          <!-- <div class="md-layout">

            <div class="md-layout-item md-small-size-100">
              <div class="md-layout md-size-15">
                <label class="md-layout-item md-size-15 md-form-label">
                  Range Search<br><br>
                </label>
                <div class="md-layout-item ">
                  <md-field>
                    <label for="movie">Emotion</label>
                    <md-select v-model="movie" name="movie" id="movie">
                      <md-option value="fight-club">Emotion 1</md-option>
                      <md-option value="godfather">Emotion 2</md-option>
                      <md-option value="godfather-ii">Emotion 3</md-option>
                      <md-option value="godfather-iii">Emotion 4</md-option>
                      <md-option value="godfellas">Emotion 5</md-option>
                      <md-option value="pulp-fiction">Emotion 6</md-option>
                      <md-option value="scarface">Emotion 7</md-option>
                    </md-select>
                  </md-field> 
                  
                </div>
              </div>
            </div>
          </div> -->
          <div class="md-layout">
            <!-- <div class="md-layout-item md-small-size-100">

              <div class="text-center">
                <div class="text-center">
                  <div class="md-layout">
                    <label class="md-layout-item md-size-15 md-form-label">
                      VAD Tags
                    </label>
                    <div class="md-layout-item">
                      <md-chips v-model="fruits" class="md-primary" style="padding-top: 0;"></md-chips>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
           
          </div>
          <!-- <div class="md-layout-item md-size-50 md-small-size-100">
            <h4 class="card-title">Sliders</h4>

            <br /> -->
          <!-- <slider v-model="sliders.rangeSlider" type="info" :connect="true">
            </slider> -->
          <!-- </div> -->
          <!-- <div class="md-layout">
            <label class="md-layout-item md-size-15 md-form-label">
              Checkboxes and Radios
            </label>
            <div class="md-layout-item">
              <md-checkbox v-model="checkbox1">First Checkbox</md-checkbox>
              <md-checkbox v-model="checkbox2">Second Checkbox</md-checkbox>
              <md-radio v-model="radio" :value="false">First Radio</md-radio>
              <md-radio v-model="radio" :value="true">Second Radio</md-radio>
            </div>
          </div> -->
          <!-- <div class="md-layout">
            <label class="md-layout-item md-size-15 md-form-label">
              Inline Checkboxes
            </label>
            <div class="md-layout-item md-inline-checkboxes">
              <md-checkbox v-model="checkbox3">a</md-checkbox>
              <md-checkbox v-model="checkbox4">b</md-checkbox>
              <md-checkbox v-model="checkbox5">c</md-checkbox>
            </div>
          </div> -->
        </md-card-content>
        <md-card-actions md-alignment="left">
          <md-button class="md-success">Submit</md-button>
        </md-card-actions>
      </md-card>
    </div>

    <!-- <div class="md-layout-item md-small-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>today</md-icon>
          </div>
          <h4 class="title">Datepicker - <small>close on select</small></h4>
        </md-card-header>

        <md-card-content>
          <md-datepicker v-model="selectedClose" md-immediately />
        </md-card-content>
      </md-card>
    </div> -->
    <!-- <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-content>
          <div class="md-layout">
            <div class="md-layout-item md-size-50 md-small-size-100">
              <h4 class="card-title">Toogle Switches</h4>
              <md-switch v-model="switch1">Value is on</md-switch>
              <md-switch v-model="switch2">Value is off</md-switch>
            </div>
            <div class="md-layout-item md-size-50 md-small-size-100">
              <h4 class="card-title">Customisable Select</h4>
              <div class="md-layout">
                <div class="md-layout-item">
                  <md-field>
                    <label for="movie">Emotion</label>
                    <md-select v-model="movie" name="movie" id="movie">
                      <md-option value="fight-club">Emotion 1</md-option>
                      <md-option value="godfather">Emotion 2</md-option>
                      <md-option value="godfather-ii">Emotion 3</md-option>
                      <md-option value="godfather-iii">Emotion 4</md-option>
                      <md-option value="godfellas">Emotion 5</md-option>
                      <md-option value="pulp-fiction">Emotion 6</md-option>
                      <md-option value="scarface">Emotion 7</md-option>
                    </md-select>
                  </md-field>
                </div>
                <div class="md-layout-item">
                  <md-field>
                    <label for="movies">Emotions</label>
                    <md-select v-model="selectedMovies" name="movies" id="movies" multiple>
                      <md-option value="fight-club">Emotion 1</md-option>
                      <md-option value="godfather">Emotion 2</md-option>
                      <md-option value="godfather-ii">Emotion 3</md-option>
                      <md-option value="godfather-iii">Emotion 4</md-option>
                      <md-option value="godfellas">Emotion 5</md-option>
                      <md-option value="pulp-fiction">Emotion 6</md-option>
                      <md-option value="scarface">Emotion 7</md-option>
                    </md-select>
                  </md-field>
                </div>
              </div>
            </div>
            <div class="md-layout-item md-size-50 md-small-size-100">
              <h4 class="card-title">Tags</h4>
              <md-chips v-model="fruits" class="md-primary" md-placeholder="Add tags..."></md-chips>
            </div> -->
    <!-- <div class="md-layout-item md-size-50 md-small-size-100">
              <h4 class="card-title">Dropdown & Dropup</h4>
              <div class="md-layout">
                <div class="md-layout-item md-size-50 md-xsmall-size-100">
                  <drop-down direction="down" multiLevel>
                    <md-button slot="title" class="md-button md-success md-round md-block dropdown-toggle"
                      data-toggle="dropdown">
                      Multilevel Dropdown
                    </md-button>
                    <ul class="dropdown-menu" :class="{ 'dropdown-menu-right': responsive }">
                      <li><a href="#">Action</a></li>
                      <li><a href="#">Another Action</a></li>
                      <li>
                        <a class="dropdown-toggle" :class="{ open: multiLevel }" @click="toggleMultiLevel">Submenu</a>
                        <ul class="dropdown-menu">
                          <li><a href="#">Submenu action</a></li>
                          <li><a href="#">Submenu action</a></li>
                          <li>
                            <a class="dropdown-toggle" :class="{ open: multiLevel2 }"
                              @click="toggleMultiLevel2()">Subsubmenu</a>
                            <ul class="dropdown-menu" :class="{ 'dropdown-menu-right': responsive }">
                              <li><a href="#">Subsubmenu action 1</a></li>
                              <li><a href="#">Subsubmenu action 2</a></li>
                            </ul>
                          </li>
                          <li>
                            <a class="dropdown-toggle" :class="{ open: multiLevel3 }" @click="toggleMultiLevel3">Second
                              Subsubmenu</a>
                            <ul class="dropdown-menu" :class="{ 'dropdown-menu-right': responsive }">
                              <li><a href="#">Subsubmenu action 1</a></li>
                              <li><a href="#">Subsubmenu action 2</a></li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </drop-down>
                </div>
                <div class="md-layout-item md-size-50 md-xsmall-size-100">
                  <drop-down direction="down">
                    <md-button slot="title" class="md-button md-success md-round md-block dropdown-toggle"
                      data-toggle="dropdown">
                      Dropdown
                    </md-button>
                    <ul class="dropdown-menu dropdown-menu-right">
                      <li><a href="#">Mike John responded to your email</a></li>
                      <li><a href="#">You have 5 new tasks</a></li>
                      <li><a href="#">You're now friend with Andrew</a></li>
                      <li><a href="#">Another Notification</a></li>
                      <li><a href="#">Another One</a></li>
                    </ul>
                  </drop-down>
                </div>
                <div class="md-layout-item md-size-50 mx-auto md-xsmall-size-100">
                  <drop-down direction="up">
                    <md-button slot="title" class="md-button md-success md-round md-block dropdown-toggle"
                      data-toggle="dropdown">
                      Dropup
                    </md-button>
                    <ul class="dropdown-menu dropdown-menu-right">
                      <li><a href="#">Mike John responded to your email</a></li>
                      <li><a href="#">You have 5 new tasks</a></li>
                      <li><a href="#">You're now friend with Andrew</a></li>
                      <li><a href="#">Another Notification</a></li>
                      <li><a href="#">Another One</a></li>
                    </ul>
                  </drop-down>
                </div>
              </div>
            </div> -->
    <!-- <div class="md-layout-item md-size-50 md-small-size-100">
              <h4 class="card-title">Progress Bar</h4>
              <md-progress-bar class="md-primary" :md-value="amount"></md-progress-bar>
              <md-progress-bar class="md-info" :md-value="amount2"></md-progress-bar>
              <md-progress-bar class="md-warning" md-mode="buffer" :md-value="buffer"
                :md-buffer="buffer"></md-progress-bar>
            </div> -->
    <!-- <div class="md-layout-item md-size-50 md-small-size-100">
              <h4 class="card-title">Sliders</h4>
              <slider v-model="sliders.simple"> </slider>
              <br />
              <slider v-model="sliders.rangeSlider" type="info" :connect="true">
              </slider>
            </div> -->
    <!-- <div class="md-layout-item md-size-30 md-xsmall-size-100">
              <h4 class="card-title">Regular Image</h4>
              <div class="file-input">
                <div v-if="!imageRegular">
                  <div class="image-container">
                    <img :src="regularImg" title="" />
                  </div>
                </div>
                <div class="image-container" v-else>
                  <img :src="imageRegular" />
                </div>
                <div class="button-container">
                  <md-button class="md-danger md-round" @click="removeImage" v-if="imageRegular"><i
                      class="fa fa-times"></i>Remove</md-button>
                  <md-button class="md-success md-round md-fileinput">
                    <template v-if="!imageRegular">Select image</template>
<template v-else>Change</template>
<input type="file" @change="onFileChange" />
</md-button>
</div>
</div>
</div> -->
    <!-- <div class="md-layout-item md-size-25">
              <h4 class="card-title">Avatar Image</h4>
              <div class="file-input img-circle">
                <template v-if="!imageCircle">
                  <div class="image-container">
                    <img :src="avatarImg" title="" />
                  </div>
                </template>
                <div class="image-container" v-else>
                  <img :src="imageCircle" />
                </div>
                <div class="button-container">
                  <md-button class="md-danger md-round" @click="removeImage('circle')" v-if="imageCircle"><i
                      class="fa fa-times"></i>Remove</md-button>
                  <md-button class="md-success md-round md-fileinput">
                    <template v-if="!imageCircle">Add Photo</template>
                    <template v-else>Change</template>
                    <input type="file" name="circle" @change="onFileChange" />
                  </md-button>
                </div>
              </div>
            </div> -->
    <!-- </div>
  </md-card-content>
  </md-card>
  </div> -->

    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>fact_check</md-icon>
          </div>
          <h4 class="title">OVERVIEW</h4>
        </md-card-header>
        <md-card-content>

          <div class="md-layout-item md-size-25">
            <md-field>
              <label for="movie">Sorting by</label>
              <md-select v-model="movie" name="movie" id="movie">
                <md-option value="fight-club">TITLE</md-option>
                <md-option value="godfather">AUTHOR</md-option>
                <md-option value="godfather-ii">RELEVANCE</md-option>
              </md-select>
            </md-field>
          </div>

          <md-table v-model="tableData">
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="TITLE">{{ item.title }}</md-table-cell>
              <md-table-cell md-label="DESCRIPTION">{{ item.description }}</md-table-cell>
              <md-table-cell md-label="AUTHOR">{{ item.author }}</md-table-cell>
              <md-table-cell md-label="LINK" :class="getAlignClasses(item)">
                <md-button class="md-just-icon" :class="getClass(item.icon1, item.id)"><md-icon>{{ item.icon1
                    }}</md-icon></md-button>
                <!-- <md-button class="md-just-icon" :class="getClass(item.icon2, item.id)"><md-icon>{{ item.icon2
                    }}</md-icon></md-button>
                <md-button class="md-just-icon" :class="getClass(item.icon3, item.id)"><md-icon>{{ item.icon3
                    }}</md-icon></md-button> -->
              </md-table-cell>
              <md-table-cell md-label="OBJECT ID">{{ item.objectid }}</md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
      </md-card>
    </div>


  </div>
</template>
<script>
import { Slider } from "@/components";
export default {
  components: {
    Slider,
  },
  props: {
    regularImg: {
      type: String,
      default: "./img/image_placeholder.jpg",
    },
    avatarImg: {
      type: String,
      default: "./img/placeholder.jpg",
    },
  },
  data() {
    return {
      layout1: null,
      layout2: null,
      layout3: null,
      emailadress: null,
      emailadress1: null,
      placeholder: null,
      password: null,
      success: null,
      error: null,
      password1: null,
      password2: null,
      boolean: false,
      disabled: null,
      boolean1: false,
      helper: null,
      checkbox1: null,
      checkbox2: null,
      checkbox3: null,
      checkbox4: null,
      checkbox5: null,
      checkbox6: true,
      checkbox7: null,
      checkbox8: true,
      checkbox9: null,
      radio: true,
      radio1: true,
      radio2: true,
      responsive: false,
      imageRegular: "",
      imageCircle: "",
      amount: 30,
      amount2: 60,
      buffer: 40,
      multiLevel: false,
      multiLevel2: false,
      multiLevel3: false,
      selectedLabeled: null,
      selectedDate: new Date("2018/03/26"),
      selectedClose: null,
      switch1: true,
      switch2: null,
      movie: "godfather",
      selectedMovies: [],
      fruits: ["funny", "scary", "sad", "neutral"],
      sliders: {
        simple1: 40,
        simple2: 60,
        simple3: 20,
        rangeSlider: [20, 60],
      },
      tableData: [
        {
          objectid: "661cdfb636cf0975a431481a",
          title: "Die verschwundene Braut",
          description: "Die Geschichte ist auch unter dem Namen 'Der adlige Junggeselle' bekannt. Die Braut von Lord Robert St Simon ist spurlos verschwunden. Hilfe suchend wendet er sich an Holmes und Watson ...",
          author: "Sir Arthur Conan Doyle",
          year: "2020",
          cover: "./img/book1.jpg",
                    icon1: "info",
        },
        {
          objectid: "661cdfb636cf0975a431481b",
          title: "Ligeia",
          description: "Der Erzähler hat die schöne und geheimnisvolle Ligeia geheiratet. Sie glaubt, dass das Sterben der Menschen nur auf einem Mangel an Willenskraft beruht. Nach ihrem Tod passiert tatsächlich Unerklärliches ...",
          author: "Edgar Allan Poe",
          year: "1838",
          cover: "./img/book2.jpg",
                    icon1: "info",
        },
        {
          objectid: "661cdfb636cf0975a431481c",
          title: "William Wilson",
          description: "Eine autobiografisch gefärbte Doppelgänger-Erzählung vom Meister der Spannungsliteratur.",
          author: "Edgar Allan Poe",
          year: "1984",
          cover: "./img/book3.jpg",
                    icon1: "info",
        },
        {
          objectid: "661cdfb636cf0975a431481d",
          title: "Die krumme Janet",
          description: "Eine Gespenstergeschichte des Autors von Die Schatzinsel über eine vom Teufel besessene Frau, die als Haushälterin für einen gottesfürchtigen Dorf-Pastor arbeitete.",
          author: "Robert Louis Stevenson",
          year: "1881",
          cover: "./img/book4.jpg",
                    icon1: "info",
        },
        {
          objectid: "661cdfb636cf0975a431481e",
          title: "Die Lektion",
          description: "Der exzentrische Geizhals Angus Blidfield wird in seinem Anwesen in London ermordet. Die Hauptverdächtige ist von Scotland Yard Inspektor Rater schnell ermittelt: Die Universalerbin und als Bedienstete in seinem Haus gehaltene Person; seine Nichte Agnes Olford. Der Fall scheint gelöst.",
          author: "Edgar Wallace",
          year: "2022",
          cover: "./img/book5.jpg",
          icon1: "info",
        },
      ],
    };
  },
  computed: {
    shoppingTotal() {
      return this.shoppingCartTable.reduce((accumulator, current) => {
        return accumulator + current.amount;
      }, 0);
    },
  },
  methods: {
    toggleMultiLevel() {
      this.multiLevel = !this.multiLevel;
    },
    toggleMultiLevel2() {
      this.multiLevel2 = !this.multiLevel2;
      this.multiLevel3 = false;
    },
    toggleMultiLevel3() {
      this.multiLevel3 = !this.multiLevel3;
      this.multiLevel2 = false;
    },
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      if (e.target.name) {
        this.createImage(files[0], "circle");
      } else {
        this.createImage(files[0]);
      }
    },
    createImage(file, type) {
      let reader = new FileReader();
      let vm = this;

      reader.onload = (e) => {
        if (type === "circle") {
          vm.imageCircle = e.target.result;
        } else {
          vm.imageRegular = e.target.result;
        }
      };
      reader.readAsDataURL(file);
    },
    removeImage: function (type) {
      if (type === "circle") {
        this.imageCircle = "";
      } else {
        this.imageRegular = "";
      }
    },
    onResponsiveInverted() {
      if (window.innerWidth < 768) {
        this.responsive = true;
      } else {
        this.responsive = false;
      }
    },
    getClass: function (item, id) {
      let classes = "";
      switch (item) {
        case "info": {
          classes = "md-info";
          break;
        }
        case "edit": {
          classes = "md-success";
          break;
        }
        case "close": {
          classes = "md-danger";
          break;
        }
      }
      switch (id) {
        case 1:
        case 5: {
          break;
        }
        case 2:
        case 4: {
          // classes = `${classes} md-round`;
          break;
        }
        case 3: {
          // classes = `${classes} md-simple`;
          break;
        }
      }
      return classes;
    },
    getAlignClasses: ({ id }) => ({
      "text-left": id,
    }),
    increaseQuantity(item) {
      item.qty++;
      this.computeAmount(item);
    },
    decreaseQuantity(item) {
      if (item.qty > 1) {
        item.qty--;
        this.computeAmount(item);
      }
    },
    computeAmount(item) {
      item.amount = item.qty * item.price;
    },
  },
  mounted() {
    this.onResponsiveInverted();
    window.addEventListener("resize", this.onResponsiveInverted);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResponsiveInverted);
  },
};
</script>
<style lang="scss" scoped>
.text-right .md-table-cell-container {
  display: flex;
  justify-content: flex-end;
}

.md-table .md-table-head:last-child {
  text-align: right;
}

.table-stats {
  display: flex;
  align-items: center;
  text-align: right;
  flex-flow: row wrap;

  .td-price .td-total {
    display: inline-flex;
    font-weight: 500;
    font-size: 1.0625rem;
    margin-right: 50px;
  }

  &.table-striped .td-price {
    border-bottom: 0;
  }

  .td-price {
    font-size: 26px;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  }

  .td-price,
  >div {
    flex: 0 0 100%;
    padding: 12px 8px;
  }
}

.table-shopping .md-table-head:nth-child(5),
.table-shopping .md-table-head:nth-child(7),
.table-shopping .md-table-head:nth-child(6) {
  text-align: right;
}
</style>
