<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="validate">
      <div>
        <h5 class="info-text">
          Let's start with the basic information
        </h5>
        <div class="md-layout">
          <!-- <div class="md-layout-item md-size-40 md-small-size-50">
            <div class="picture-container">
              <div class="picture">
                <div v-if="!image">
                  <img :src="image" class="picture-src" title="" />
                </div>
                <div v-else>
                  <img :src="image" />
                </div>
                <input type="file" @change="onFileChange" />
              </div>
              <h6 class="description">Choose Cover</h6>
            </div>
          </div> -->


          <div class="md-layout-item md-size-30 md-xsmall-size-100">
            <h4 class="card-title">Audio Book Cover</h4>
            <div class="file-input">
              <div v-if="!image">
                <div class="image-container">
                  <!-- <img :src=" image" title="" /> -->
                </div>
              </div>
              <div class="image-container" v-else>
                <img :src="image" />
              </div>
              <div class="button-container">
                <md-button class="md-success md-round md-fileinput">
                  <template v-if="!image">Select Cover</template>
                  <template v-else>Change Cover</template>
                  <input type="file" @change="onFileChange" />
                </md-button>
              </div>
            </div>
          </div>





          <div class="md-layout-item md-size-60 mt-4 md-small-size-100">
            <ValidationProvider name="firstName" rules="required" v-slot="{ passed, failed }">
              <md-field :class="[
      { 'md-error': failed },
      { 'md-valid': passed },
      { 'md-form-group': true },
    ]">

                <md-icon>label_important</md-icon>
                <label>Title</label>
                <md-input v-model="firstName" type="text"> </md-input>

                <slide-y-down-transition>
                  <md-icon class="error" v-show="failed">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="passed">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </ValidationProvider>

            <ValidationProvider name="lastName" rules="required" v-slot="{ passed, failed }">
              <md-field :class="[
      { 'md-error': failed },
      { 'md-valid': passed },
      { 'md-form-group': true },
    ]">
                <md-icon>description</md-icon>
                <label>Description</label>
                <md-input v-model="lastName1" type="text"> </md-input>

                <slide-y-down-transition>
                  <md-icon class="error" v-show="failed">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="passed">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </ValidationProvider>
            <ValidationProvider name="lastName" rules="required" v-slot="{ passed, failed }">
              <md-field :class="[
      { 'md-error': failed },
      { 'md-valid': passed },
      { 'md-form-group': true },
    ]">
                <md-icon>record_voice_over</md-icon>
                <label>Author</label>
                <md-input v-model="lastName2" type="text"> </md-input>

                <slide-y-down-transition>
                  <md-icon class="error" v-show="failed">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="passed">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </ValidationProvider>
            <ValidationProvider name="lastName" rules="required" v-slot="{ passed, failed }">
              <md-field :class="[
      { 'md-error': failed },
      { 'md-valid': passed },
      { 'md-form-group': true },
    ]">
                <md-icon>settings_voice</md-icon>
                <label>Speaker</label>
                <md-input v-model="lastName3" type="text"> </md-input>

                <slide-y-down-transition>
                  <md-icon class="error" v-show="failed">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="passed">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </ValidationProvider>

            <md-field :class="[
      { 'md-error': failed },
      { 'md-valid': passed },
      { 'md-form-group': true },
    ]">
              <md-icon>volume_up</md-icon>
              <label>Audio Files</label>
              <md-input v-model="audiofiles" type="text"> </md-input>
              <md-button v-model="audiofiles" class="md-success md-round md-fileinput" type="file">
                <template>Select Audio Files</template>
                <input type="file" @change="onFileChange1" />
              </md-button>

              <slide-y-down-transition>
                <md-icon class="error" v-show="failed">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="passed">done</md-icon>
              </slide-y-down-transition>
            </md-field>

          </div>
          <!-- <div class="md-layout-item md-size-95 ml-auto mt-4 md-small-size-100"> -->
          <!-- <ValidationProvider name="email" rules="required|email" v-slot="{ passed, failed }">
              <md-field :class="[
      { 'md-error': failed },
      { 'md-valid': passed },
      { 'md-form-group': true },
    ]">

                <slide-y-down-transition>
                  <md-icon class="error" v-show="failed">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="passed">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </ValidationProvider> -->
          <!-- </div> -->
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import { SlideYDownTransition } from "vue2-transitions";
import { extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";

extend("required", required);
extend("email", email);

export default {
  components: {
    SlideYDownTransition,
  },
  props: {
    avatar: {
      type: String,
      default: "./img/default-avatar.png",
    },
  },
  data() {
    return {
      image: "",
      firstName: "",
      lastName1: "",
      lastName2: "",
      lastName3: "",
      email: "wolfgang.goers@uni-leipzig.de",
      audiofiles: "",
    };
  },
  methods: {
    handlePreview(file) {
      this.model.imageUrl = URL.createObjectURL(file.raw);
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },
    onFileChange1(e) {
      this.audiofiles = e.target.files[0].name || e.dataTransfer.files;
      if (!audiofiles.length) return;
      // this.createImage(audiofiles[0]);
    },
    validate() {
      return this.$refs.form.validate().then((res) => {
        this.$emit("on-validated", res);
        return res;
      });
    },
    createImage(file) {
      var reader = new FileReader();
      var vm = this;

      reader.onload = (e) => {
        vm.image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
  },
};
</script>
<style></style>
