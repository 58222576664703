<template>
  <div class="md-layout">

    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-text md-card-header-green">
          <div class="card-text">
            <h4 class="title">Audio Book Details</h4>
          </div>
        </md-card-header>

        <md-card-content>
          <div class="md-layout-item md-size-100 md-small-size-100 text-right">
            <div class="md-group">
              <md-button @click="getFirst" class="md-round md-rose" style="margin: 0.25rem;">First</md-button>
              <md-button @click="getPrevious" class=" md-round md-secondary" style="margin: 0.25rem;">Previous</md-button>
              <md-button @click="getNext" class="md-round md-secondary" style="margin: 0.25rem;">Next</md-button>
              <md-button @click="getLast" class="md-round md-rose" style="margin: 0.25rem;">Last</md-button>
            </div>

          </div>


          <div class="md-layout">
            <div class="md-layout-item md-size-25 md-xsmall-size-100">

              <div class="md-layout-item">
                <div class="image-container">
                  <img :src="this.books[bookID].cover" title="" />
                </div>

              </div>

            </div>
            <div class="md-layout-item md-xsmall-size-100">

              <div class="md-layout">
                <label class="md-layout-item md-size-15 md-form-label">
                  Title
                </label>
                <div class="md-layout-item">
                  <md-field>
                    <md-input v-model="books[bookID].title" type="text"></md-input>
                    <span class="md-helper-text">the title of the audio book</span>
                  </md-field>
                </div>
              </div>

              <div class="md-layout">
                <label class="md-layout-item md-size-15 md-form-label">
                  Description
                </label>
                <div class="md-layout-item">
                  <md-field>
                    <md-input v-model="books[bookID].description" type="text"></md-input>
                    <span class="md-helper-text">the description of the audio book</span>
                  </md-field>
                </div>
              </div>

              <div class="md-layout">
                <label class="md-layout-item md-size-15 md-form-label">
                  Author
                </label>
                <div class="md-layout-item">
                  <md-field>
                    <md-input v-model="books[bookID].author" type="text"></md-input>
                    <span class="md-helper-text">the author of the audio book</span>
                  </md-field>
                </div>
              </div>

              <div class="md-layout">
                <label class="md-layout-item md-size-15 md-form-label">
                  Year
                </label>
                <div class="md-layout-item">
                  <md-field>
                    <md-input v-model="books[bookID].year" type="text"></md-input>
                    <span class="md-helper-text">the year of the audio book</span>
                  </md-field>
                </div>
              </div>

              <div class="md-layout">
                <label class="md-layout-item md-size-15 md-form-label">
                  VAD Tags
                </label>
                <div class="md-layout-item">
                  <md-chips v-model="fruits" class="md-primary" style="padding-top: 0;"></md-chips>
                </div>
              </div>

              <div class="md-layout" style="margin-top: -25px;">
                <label class="md-layout-item md-size-15 md-form-label">
                  Thema Codes
                </label>
                <div class="md-layout-item">
                  <md-chips v-model="fruits1" class="md-primary" style="padding-top: 0;"></md-chips>
                </div>
              </div>


            </div>

          </div>









          <!-- <div class="md-layout">
            <label class="md-layout-item md-size-15 md-form-label">
              Checkboxes and Radios
            </label>
            <div class="md-layout-item">
              <md-checkbox v-model="checkbox1">First Checkbox</md-checkbox>
              <md-checkbox v-model="checkbox2">Second Checkbox</md-checkbox>
              <md-radio v-model="radio" :value="false">First Radio</md-radio>
              <md-radio v-model="radio" :value="true">Second Radio</md-radio>
            </div>
          </div> -->

          <!-- <div class="md-layout">

            <div class="md-layout-item md-size-50 md-medium-size-100">
              <tabs :tab-name="['General', 'Production', 'Speaker']" flex-column color-button="success">
                <h4 class="title" slot="header-title">
                  Audio Information
                </h4>

                <template slot="tab-pane-1">
                  <div class="md-layout">
                    <label class="md-layout-item  md-form-label">
                      Volume
                    </label>
                    <div class="md-layout-item">
                      <md-field>
                        <md-input v-model="dummy1" type="text"></md-input>
                        <span class="md-helper-text">the helper text for the value</span>
                      </md-field>
                    </div>
                  </div>

                  <div class="md-layout">
                    <label class="md-layout-item  md-form-label">
                      Bit rate
                    </label>
                    <div class="md-layout-item">
                      <md-field>
                        <md-input v-model="dummy1" type="text"></md-input>
                        <span class="md-helper-text">the helper text for the value</span>
                      </md-field>
                    </div>
                  </div>

                  <div class="md-layout">
                    <label class="md-layout-item  md-form-label">
                      spectral analysis
                    </label>
                    <div class="md-layout-item">
                      <md-field>
                        <md-input v-model="dummy1" type="text"></md-input>
                        <span class="md-helper-text">the helper text for the value</span>
                      </md-field>
                    </div>
                  </div>

                  <div class="md-layout">
                    <label class="md-layout-item  md-form-label">
                      duration
                    </label>
                    <div class="md-layout-item">
                      <md-field>
                        <md-input v-model="dummy1" type="text"></md-input>
                        <span class="md-helper-text">the helper text for the value</span>
                      </md-field>
                    </div>
                  </div>

                  <div class="md-layout">
                    <label class="md-layout-item  md-form-label">
                      frequency/bandwidth
                    </label>
                    <div class="md-layout-item">
                      <md-field>
                        <md-input v-model="dummy1" type="text"></md-input>
                        <span class="md-helper-text">the helper text for the value</span>
                      </md-field>
                    </div>
                  </div>

                </template>
<template slot="tab-pane-2">
                  <div class="md-layout">
                    <label class="md-layout-item  md-form-label">
                      Intonation
                    </label>
                    <div class="md-layout-item">
                      <md-field>
                        <md-input v-model="dummy1" type="text"></md-input>
                        <span class="md-helper-text">the helper text for the value</span>
                      </md-field>
                    </div>
                  </div>

                  <div class="md-layout">
                    <label class="md-layout-item  md-form-label">
                      Melody
                    </label>
                    <div class="md-layout-item">
                      <md-field>
                        <md-input v-model="dummy1" type="text"></md-input>
                        <span class="md-helper-text">the helper text for the value</span>
                      </md-field>
                    </div>
                  </div>

                  <div class="md-layout">
                    <label class="md-layout-item  md-form-label">
                      Pitch Range
                    </label>
                    <div class="md-layout-item">
                      <md-field>
                        <md-input v-model="dummy1" type="text"></md-input>
                        <span class="md-helper-text">the helper text for the value</span>
                      </md-field>
                    </div>
                  </div>

                  <div class="md-layout">
                    <label class="md-layout-item  md-form-label">
                      Dynamic
                    </label>
                    <div class="md-layout-item">
                      <md-field>
                        <md-input v-model="dummy1" type="text"></md-input>
                        <span class="md-helper-text">the helper text for the value</span>
                      </md-field>
                    </div>
                  </div>

                  <div class="md-layout">
                    <label class="md-layout-item  md-form-label">
                      Speaking Rate
                    </label>
                    <div class="md-layout-item">
                      <md-field>
                        <md-input v-model="dummy1" type="text"></md-input>
                        <span class="md-helper-text">the helper text for the value</span>
                      </md-field>
                    </div>
                  </div>
                </template>
<template slot="tab-pane-3">
                  <div class="md-layout">
                    <label class="md-layout-item  md-form-label">
                      First Name
                    </label>
                    <div class="md-layout-item">
                      <md-field>
                        <md-input v-model="dummy1" type="text"></md-input>
                        <span class="md-helper-text">the helper text for the value</span>
                      </md-field>
                    </div>
                  </div>

                  <div class="md-layout">
                    <label class="md-layout-item  md-form-label">
                      Last Name
                    </label>
                    <div class="md-layout-item">
                      <md-field>
                        <md-input v-model="dummy1" type="text"></md-input>
                        <span class="md-helper-text">the helper text for the value</span>
                      </md-field>
                    </div>
                  </div>
                </template>
</tabs>
</div>

<div class="md-layout-item md-size-50 md-medium-size-100">
  <md-card>
    <md-card-header>
      <h4 class="title">Text Content Information</h4>
    </md-card-header>
    <md-card-content>
      <collapse :collapse="[
                  'Emotions',
                  'Complexity',
                ]" icon="keyboard_arrow_down" color-collapse="success">
        <template slot="md-collapse-pane-1">
                      <div class="md-layout">
                        <label class="md-layout-item  md-form-label">
                          Basic Emotion
                        </label>
                        <div class="md-layout-item">
                          <md-field>
                            <md-input v-model="dummy1" type="text"></md-input>
                            <span class="md-helper-text">the helper text for the value</span>
                          </md-field>
                        </div>
                      </div>

                      <div class="md-layout">
                        <label class="md-layout-item  md-form-label">
                          Uproariousness
                        </label>
                        <div class="md-layout-item">
                          <md-field>
                            <md-input v-model="dummy1" type="text"></md-input>
                            <span class="md-helper-text">the helper text for the value</span>
                          </md-field>
                        </div>
                      </div>

                      <div class="md-layout">
                        <label class="md-layout-item  md-form-label">
                          Dominance
                        </label>
                        <div class="md-layout-item">
                          <md-field>
                            <md-input v-model="dummy1" type="text"></md-input>
                            <span class="md-helper-text">the helper text for the value</span>
                          </md-field>
                        </div>
                      </div>
                    </template>
        <template slot="md-collapse-pane-2">
                      <div class="md-layout">
                        <label class="md-layout-item  md-form-label">
                          words per minute
                        </label>
                        <div class="md-layout-item">
                          <md-field>
                            <md-input v-model="dummy1" type="text"></md-input>
                            <span class="md-helper-text">the helper text for the value</span>
                          </md-field>
                        </div>
                      </div>

                      <div class="md-layout">
                        <label class="md-layout-item  md-form-label">
                          foreign word frequency
                        </label>
                        <div class="md-layout-item">
                          <md-field>
                            <md-input v-model="dummy1" type="text"></md-input>
                            <span class="md-helper-text">the helper text for the value</span>
                          </md-field>
                        </div>
                      </div>

                      <div class="md-layout">
                        <label class="md-layout-item  md-form-label">
                          readability score
                        </label>
                        <div class="md-layout-item">
                          <md-field>
                            <md-input v-model="dummy1" type="text"></md-input>
                            <span class="md-helper-text">the helper text for the value</span>
                          </md-field>
                        </div>
                      </div>

                      <div class="md-layout">
                        <label class="md-layout-item  md-form-label">
                          age of acquisition
                        </label>
                        <div class="md-layout-item">
                          <md-field>
                            <md-input v-model="dummy1" type="text"></md-input>
                            <span class="md-helper-text">the helper text for the value</span>
                          </md-field>
                        </div>
                      </div>
                    </template>
      </collapse>
    </md-card-content>
  </md-card>
</div> -->


          <!-- </div> -->

          <div class="md-layout-item md-size-100">
            <md-card>
              <md-card-header class="md-card-header-icon md-card-header-green">
                <div class="card-icon">
                  <md-icon>assignment</md-icon>
                </div>
                <h4 class="title">Related Audio Books</h4>
              </md-card-header>
              <md-card-content>
                <md-table v-model="tableData" table-header-color="green">
                  <md-table-row slot="md-table-row" slot-scope="{ item }">
                    <md-table-cell md-label="TITLE">{{ item.name }}</md-table-cell>
                    <md-table-cell md-label="AUTHOR">{{
                    item.country
                  }}</md-table-cell>
                    <md-table-cell md-label="LINK" :class="getAlignClasses(item)">
                      <md-button class="md-just-icon" :class="getClass(item.icon1, item.id)"><md-icon>{{ item.icon1
                          }}</md-icon></md-button>
                    </md-table-cell>
                    <md-table-cell md-label="ObjectID">{{ item.salary }}</md-table-cell>
                  </md-table-row>
                </md-table>
              </md-card-content>
            </md-card>
          </div>

        </md-card-content>
      </md-card>
    </div>



  </div>
</template>
<script>
// import { Tabs } from "@/components";
// import { Collapse } from "@/components";
export default {
  components: {
    // Tabs,
    // Collapse,
  },
  props: {
    regularImg: {
      type: String,
      default: "./img/cover-placeholder.jpg",
    },
    avatarImg: {
      type: String,
      default: "./img/placeholder.jpg",
    },
  },
  data() {
    return {
      fruits: ["vad-1", "vad-2", "vad-3", "vad-4", "vad-5"],
      fruits1: ["thema-code-1", "thema-code-2", "thema-code-3"],
      tableData: [
        {
          id: 1,
          name: "audio-book-title-one",
          salary: "661cdfb636cf0975a431481b",
          country: "author-one",
          city: "Oud-Turnhout",
          icon1: "info",
          icon2: "edit",
          icon3: "close",
        },
        {
          id: 2,
          name: "audio-book-title-two",
          salary: "661cdfb636cf0975a431481b",
          country: "author-two",
          city: "Sinaai-Waas",
          icon1: "info",
          icon2: "edit",
          icon3: "close",
        },
        {
          id: 3,
          name: "audio-book-title-three",
          salary: "661cdfb636cf0975a431481b",
          country: "author-three",
          city: "Baileux",
          icon1: "info",
          icon2: "edit",
          icon3: "close",
        },
        {
          id: 4,
          name: "audio-book-title-four",
          salary: "661cdfb636cf0975a431481b",
          country: "author-four",
          city: "Overland Park",
          icon1: "info",
          icon2: "edit",
          icon3: "close",
        },
        {
          id: 5,
          name: "audio-book-title-five",
          salary: "661cdfb636cf0975a431481b",
          country: "author-five",
          city: "Feldkirchen in Kärnten",
          icon1: "info",
          icon2: "edit",
          icon3: "close",
        },
      ],
      bookID: 0,
      books: [
        {
          objectid: "661cdfb636cf0975a431481a",
          title: "Die verschwundene Braut",
          description: "Die Geschichte ist auch unter dem Namen 'Der adlige Junggeselle' bekannt. Die Braut von Lord Robert St Simon ist spurlos verschwunden. Hilfe suchend wendet er sich an Holmes und Watson ...",
          author: "Sir Arthur Conan Doyle",
          year: "2020",
          cover: "./img/book1.jpg"
        },
        {
          objectid: "661cdfb636cf0975a431481b",
          title: "Ligeia",
          description: "Der Erzähler hat die schöne und geheimnisvolle Ligeia geheiratet. Sie glaubt, dass das Sterben der Menschen nur auf einem Mangel an Willenskraft beruht. Nach ihrem Tod passiert tatsächlich Unerklärliches ...",
          author: "Edgar Allan Poe",
          year: "1838",
          cover: "./img/book2.jpg"
        },
        {
          objectid: "661cdfb636cf0975a431481c",
          title: "William Wilson",
          description: "Eine autobiografisch gefärbte Doppelgänger-Erzählung vom Meister der Spannungsliteratur.",
          author: "Edgar Allan Poe",
          year: "1984",
          cover: "./img/book3.jpg"
        },
        {
          objectid: "661cdfb636cf0975a431481d",
          title: "Die krumme Janet",
          description: "Eine Gespenstergeschichte des Autors von Die Schatzinsel über eine vom Teufel besessene Frau, die als Haushälterin für einen gottesfürchtigen Dorf-Pastor arbeitete.",
          author: "Robert Louis Stevenson",
          year: "1881",
          cover: "./img/book4.jpg"
        },
        {
          objectid: "661cdfb636cf0975a431481e",
          title: "Die Lektion",
          description: "Der exzentrische Geizhals Angus Blidfield wird in seinem Anwesen in London ermordet. Die Hauptverdächtige ist von Scotland Yard Inspektor Rater schnell ermittelt: Die Universalerbin und als Bedienstete in seinem Haus gehaltene Person; seine Nichte Agnes Olford. Der Fall scheint gelöst.",
          author: "Edgar Wallace",
          year: "2022",
          cover: "./img/book5.jpg"
        },
      ],
      objectid: "661cdfb636cf0975a431481b",
      title: "Audio Book One",
      description: "Description One",
      author: "Author One",
      year: "1984",
      dummy1: "value",
      layout1: null,
      layout2: null,
      layout3: null,
      emailadress: null,
      emailadress1: null,
      placeholder: null,
      password: null,
      success: null,
      error: null,
      password1: null,
      password2: null,
      boolean: false,
      disabled: null,
      boolean1: false,
      helper: null,
      checkbox1: null,
      checkbox2: null,
      checkbox3: null,
      checkbox4: null,
      checkbox5: null,
      checkbox6: true,
      checkbox7: null,
      checkbox8: true,
      checkbox9: null,
      radio: true,
      radio1: true,
      radio2: true,

    };
  },
  methods: {
    getFirst: function (event) {
      this.bookID = 0;
    },
    getLast: function () {
      this.bookID = this.books.length-1;
    },
    getNext: function () {
      if (this.bookID == (this.books.length-1)) {
        this.bookID = 0;
        
      }
      else {
        this.bookID++;
      }
    },
    getPrevious: function () {
            if (this.bookID == 0) {
        this.bookID = this.books.length-1;
      }
      else {
        this.bookID--;
      }
    },
    getClass: function (item, id) {
      let classes = "";
      switch (item) {
        case "info": {
          classes = "md-info";
          break;
        }
        case "edit": {
          classes = "md-success";
          break;
        }
        case "close": {
          classes = "md-danger";
          break;
        }
      }
      switch (id) {
        case 1:
        case 5: {
          break;
        }
        case 2:
        case 4: {
          // classes = `${classes} md-round`;
          break;
        }
        case 3: {
          // classes = `${classes} md-simple`;
          break;
        }
      }
      return classes;
    },
    getAlignClasses: ({ id }) => ({
      "text-left": id,
    }),
  }
};
</script>
<style lang="scss" scoped>
.md-card .md-card-actions {
  border: none;
}

.md-inline-checkboxes {
  display: inline-flex;

  .md-checkbox {
    margin-top: 15px !important;
  }
}

.md-checkbox,
.md-radio {
  margin-top: 15px;
  margin-bottom: 0.5rem;
}

.md-checkbox,
.md-radio {
  display: flex;
}

.md-radio .md-radio-container {
  margin-left: 5px;
  position: relative;
  left: -3px;
}

.md-form-label+.md-layout-item .md-checkbox:not(:first-child),
.md-form-label+.md-layout-item+.md-layout-item .md-radio:not(:first-child),
.md-form-label+.md-layout-item .md-radio {
  margin-top: 0;
}

.form-control-static {
  margin-top: 6px;
}
</style>
