var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.validate.apply(null, arguments)}}},[_c('div',[_c('h5',{staticClass:"info-text"},[_vm._v(" Let's start with the basic information ")]),_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-size-30 md-xsmall-size-100"},[_c('h4',{staticClass:"card-title"},[_vm._v("Audio Book Cover")]),_c('div',{staticClass:"file-input"},[(!_vm.image)?_c('div',[_c('div',{staticClass:"image-container"})]):_c('div',{staticClass:"image-container"},[_c('img',{attrs:{"src":_vm.image}})]),_c('div',{staticClass:"button-container"},[_c('md-button',{staticClass:"md-success md-round md-fileinput"},[(!_vm.image)?[_vm._v("Select Cover")]:[_vm._v("Change Cover")],_c('input',{attrs:{"type":"file"},on:{"change":_vm.onFileChange}})],2)],1)])]),_c('div',{staticClass:"md-layout-item md-size-60 mt-4 md-small-size-100"},[_c('ValidationProvider',{attrs:{"name":"firstName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('md-field',{class:[
    { 'md-error': failed },
    { 'md-valid': passed },
    { 'md-form-group': true } ]},[_c('md-icon',[_vm._v("label_important")]),_c('label',[_vm._v("Title")]),_c('md-input',{attrs:{"type":"text"},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}}),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(failed),expression:"failed"}],staticClass:"error"},[_vm._v("close")])],1),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(passed),expression:"passed"}],staticClass:"success"},[_vm._v("done")])],1)],1)]}}])}),_c('ValidationProvider',{attrs:{"name":"lastName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var passed = ref.passed;
  var failed = ref.failed;
return [_c('md-field',{class:[
    { 'md-error': failed },
    { 'md-valid': passed },
    { 'md-form-group': true } ]},[_c('md-icon',[_vm._v("description")]),_c('label',[_vm._v("Description")]),_c('md-input',{attrs:{"type":"text"},model:{value:(_vm.lastName1),callback:function ($$v) {_vm.lastName1=$$v},expression:"lastName1"}}),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(failed),expression:"failed"}],staticClass:"error"},[_vm._v("close")])],1),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(passed),expression:"passed"}],staticClass:"success"},[_vm._v("done")])],1)],1)]}}])}),_c('ValidationProvider',{attrs:{"name":"lastName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var passed = ref.passed;
  var failed = ref.failed;
return [_c('md-field',{class:[
    { 'md-error': failed },
    { 'md-valid': passed },
    { 'md-form-group': true } ]},[_c('md-icon',[_vm._v("record_voice_over")]),_c('label',[_vm._v("Author")]),_c('md-input',{attrs:{"type":"text"},model:{value:(_vm.lastName2),callback:function ($$v) {_vm.lastName2=$$v},expression:"lastName2"}}),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(failed),expression:"failed"}],staticClass:"error"},[_vm._v("close")])],1),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(passed),expression:"passed"}],staticClass:"success"},[_vm._v("done")])],1)],1)]}}])}),_c('ValidationProvider',{attrs:{"name":"lastName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var passed = ref.passed;
  var failed = ref.failed;
return [_c('md-field',{class:[
    { 'md-error': failed },
    { 'md-valid': passed },
    { 'md-form-group': true } ]},[_c('md-icon',[_vm._v("settings_voice")]),_c('label',[_vm._v("Speaker")]),_c('md-input',{attrs:{"type":"text"},model:{value:(_vm.lastName3),callback:function ($$v) {_vm.lastName3=$$v},expression:"lastName3"}}),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(failed),expression:"failed"}],staticClass:"error"},[_vm._v("close")])],1),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(passed),expression:"passed"}],staticClass:"success"},[_vm._v("done")])],1)],1)]}}])}),_c('md-field',{class:[
    { 'md-error': _vm.failed },
    { 'md-valid': _vm.passed },
    { 'md-form-group': true } ]},[_c('md-icon',[_vm._v("volume_up")]),_c('label',[_vm._v("Audio Files")]),_c('md-input',{attrs:{"type":"text"},model:{value:(_vm.audiofiles),callback:function ($$v) {_vm.audiofiles=$$v},expression:"audiofiles"}}),_c('md-button',{staticClass:"md-success md-round md-fileinput",attrs:{"type":"file"},model:{value:(_vm.audiofiles),callback:function ($$v) {_vm.audiofiles=$$v},expression:"audiofiles"}},[[_vm._v("Select Audio Files")],_c('input',{attrs:{"type":"file"},on:{"change":_vm.onFileChange1}})],2),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.failed),expression:"failed"}],staticClass:"error"},[_vm._v("close")])],1),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.passed),expression:"passed"}],staticClass:"success"},[_vm._v("done")])],1)],1)],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }