<template>
  <div class="md-layout">
    <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
      <stats-card header-color="green">
        <template slot="header">
          <div class="card-icon">
            <md-icon>equalizer</md-icon>
          </div>
          <p class="category">Anzahl der Titel in der Datenbank</p>
          <h3 class="title">
            <animated-number :value="840"></animated-number>
          </h3>
        </template>
        <template slot="footer">
          <div class="stats">
            <md-icon>update</md-icon>
            Letzte Aktualisierung: 09:00 Uhr, 13.September 2024
          </div>
        </template>
      </stats-card>
    </div>

    <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
      <stats-card header-color="green">
        <template slot="header">
          <div class="card-icon">
            <md-icon>equalizer</md-icon>
          </div>
          <p class="category">Anzahl der Autoren in der Datenbank</p>
          <h3 class="title">
            <animated-number :value="221"></animated-number>
          </h3>
        </template>
        <template slot="footer">
          <div class="stats">
            <md-icon>update</md-icon>
            Letzte Aktualisierung: 09:00 Uhr, 13.September 2024
          </div>
        </template>
      </stats-card>
    </div>

    <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
      <stats-card header-color="green">
        <template slot="header">
          <div class="card-icon">
            <md-icon>equalizer</md-icon>
          </div>
          <p class="category">Anzahl der Sprecher in der Datenbank</p>
          <h3 class="title">
            <animated-number :value="95"></animated-number>
          </h3>
        </template>
        <template slot="footer">
          <div class="stats">
            <md-icon>update</md-icon>
            Letzte Aktualisierung: 09:00 Uhr, 13.September 2024
          </div>
        </template>
      </stats-card>
    </div>

    <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
      <stats-card header-color="green">
        <template slot="header">
          <div class="card-icon">
            <md-icon>equalizer</md-icon>
          </div>
          <p class="category">Gesamtlänge der Audiodateien in der Datenbank</p>
          <h3 class="title">
            <animated-number :value="727"></animated-number>.<animated-number :value="1"></animated-number>
          </h3>
        </template>
        <template slot="footer">
          <div class="stats">
            <md-icon>update</md-icon>
            Letzte Aktualisierung: 09:00 Uhr, 13.September 2024
          </div>
        </template>
      </stats-card>
    </div>

    <!-- <div class="md-layout-item md-size-50 md-small-size-100">
      <chart-card header-animation="false" :chart-data="pieChart.data" :chart-options="pieChart.options"
        chart-type="Pie" header-icon chart-inside-content background-color="blue">
        <template slot="chartInsideHeader">
          <div class="card-icon">
            <md-icon>pie_chart</md-icon>
          </div>
          <h4 class="title">pie-chart-title-one</h4>
        </template>
        <template slot="footer">
          <div class="md-layout">
            <div class="md-layout-item md-size-100">
              <h6 class="category">Legend</h6>
            </div>
            <div class="md-layout-item">
              <i class="fa fa-circle text-info"></i> category-one
              <i class="fa fa-circle text-warning"></i> category-two
              <i class="fa fa-circle text-danger"></i> category-three
            </div>
          </div>
        </template>
      </chart-card>
    </div> -->


    <div class="md-layout-item md-size-100 md-small-size-100">
      <chart-card header-animation="false" :chart-data="simpleBarChart.data" :chart-options="simpleBarChart.options"
        :chart-responsive-options="simpleBarChart.responsiveOptions" chart-type="Bar" chart-inside-header no-footer
        background-color="green">
        <template slot="content">
          <h4 class="title">Relative Verteilung der Genres in der Datenbank</h4>
        </template>
      </chart-card>
    </div>

    <div class="md-layout-item md-size-100 md-small-size-100">
      <chart-card header-animation="false" :chart-data="pieChart.data" :chart-options="pieChart.options"
        chart-type="Pie" header-icon chart-inside-content background-color="blue">
        <template slot="chartInsideHeader">
          <div class="card-icon">
            <md-icon>pie_chart</md-icon>
          </div>
          <h4 class="title">Relative Verteilung der Genres in der Datenbank (TOP 3)</h4>
        </template>
        <template slot="footer">
          <div class="md-layout">
            <div class="md-layout-item md-size-100">
              <h6 class="category">Legende</h6>
            </div>
            <div class="md-layout-item">
              <i class="fa fa-circle text-warning"></i> Kinder & Jugendliche
              <i class="fa fa-circle text-danger"></i> Romane & Erzählungen
              <i class="fa fa-circle text-rose"></i> Lyrik & Musik
              <i class="fa fa-circle text-primary"></i> Sonstige
            </div>
          </div>
        </template>
      </chart-card>
    </div>


    <!-- <ul v-if="data">
      <li>{{ data }}</li>
    </ul>

    <ul v-if="data && !loading && !error">
      <li v-for="item in data" :key="item.mykey">{{ item }}</li>
    </ul> -->

  </div>
</template>

<script>
import {
  StatsCard,
  AnimatedNumber,
  ChartCard,
} from "@/components";

export default {
  components: {
    StatsCard,
    ChartCard,
    AnimatedNumber,
  },
  data() {
    return {
      data: "nothing",    // Hier werden die API-Daten gespeichert
      loading: true, // Ladeanzeige
      error: null,   // Fehlernachricht
      dashboardData: null,
      pieChart: {
        data: {
          labels: ["20.36%", "28.93%", "26.43%", "24.28%"],
          series: [20.36, 28.93, 26.43, 24.28],
        },
        options: {
          height: "250px",
        },
      },
      // pieChart: {
      //   data: {
      //     labels: ["8.69%", "20.36%", "28.93%", "8.57%", "4.64%", "2.38%", "26.43%"],
      //     series: [8.69, 20.36, 28.93, 8.57, 4.64, 2.38, 26.43],
      //   },
      //   options: {
      //     height: "230px",
      //   },
      // },



      simpleBarChart: {
        data: {
          labels: ["Krimi & Spannung", "Kinder & Jugendliche", "Romane & Erzählungen", "Liebe & Leidenschaft", "Sachliches & Geistiges", "Hörspiel & Bühne", "Lyrik & Musik"],
          series: [
            [73, 171, 243, 72, 39, 20, 222],
          ],
        },
        options: {
          seriesBarDistance: 10,
          height: "230px",
          axisX: {
            showGrid: false,
          },
        },
        responsiveOptions: [
          [
            "screen and (max-width: 640px)",
            {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc: function (value) {
                  return value[0];
                },
              },
            },
          ],
        ],
      },
    };
  },
  mounted() {
    this.fetchData();
    console.log('loading: ' + this.loading)
    console.log('data: ' + this.data)
  },
  methods: {
    async fetchData() {
      try {
        const response = await fetch('http://localhost:3000/api/v1/dashboard');
        // console.log(await response.json())
        this.data = await response.json();
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

      } catch (err) {
        this.error = err.message;
      } finally {
        this.loading = false;
        // console.log("data: " + this.data);
      }
    }
  }
};
</script>
